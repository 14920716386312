import Mary from '../src/assets/teamImages/Mr.jpg'
import John from '../src/assets/teamImages/Jr.jpg'
// import Jresume from '../src/assets/images/John-resume-2013.pdf'
// import Mresume from '../src/assets/images/Mary-resume-2013.pdf'


const people = [
  
        {_id: '1',
          name: 'Mary-Louise R.Rusek, M.S.',
          alt: 'Mary-Louise R. Rusek a beautiful smiling woman with short hair and glasses',
          role: 'President/Co-Owner/Scientist',
          imageUrl: Mary,
          experience1:
            '  Four years experience as a research chemist and laboratory manager for the Air Force Rocket Propulsion Laboratory at Edwards Air Force Base',
          experience2:
            ' Owner/Operator of Ad Astra, a small business specializing in rocket propulsion products, for 10 years',
          experience3: 'Makes an excellent Baklava.',
          education1: 'B.S.Art Education, Case Western Reserve University',
          education2: 'B.F.A.Cleveland Institute of Art',
      
          education3: 'B.S.Geology, Northern Illinois University',
              education4: 'M.S.Geology, Case Western Reserve University',
          
        },
        {_id: '2',
          name: 'John J. Rusek, Ph.D.',
          alt: 'John J Rusek, man in a suit and tie, gray hair, beard and smiling eyes',
          role: 'President/Co-Owner/Engineer',
          imageUrl: John,
          experience1:
            '47 years of experience in the chemical and propulsion industries',
          experience2:
            '31 years of experience in the military aerospace community as a DoD civil servant',
          experience3:
            '  25 years experience as a professor with the School of Aeronautics and Astronautics at Purdue University',
          education1: 'B.S.Chemical Engineering, Case Western Reserve University',
          education2: ' M.S.Chemical Engineering, Case Western Reserve University',
          education3:
            'Ph.D.Chemical Engineering/Atomic Physics, Case Western Reserve University',
            education4: 'M.S.Strategic Studies, USAF Air War College',
         
        },

]
export default people