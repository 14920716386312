// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1.privacyContainer { 
    text-align: center;

}
.spaceContainer {
    padding: 10em;
}
.privacyContainer {
    padding: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Privacy.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;AAEtB;AACA;IACI,aAAa;AACjB;AACA;IACI,YAAY;AAChB","sourcesContent":["h1.privacyContainer { \n    text-align: center;\n\n}\n.spaceContainer {\n    padding: 10em;\n}\n.privacyContainer {\n    padding: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
