import { useState } from 'react'

import Carousel from 'react-bootstrap/Carousel'
import LabFlowTest from '../assets/researching/LabFlowTesting.jpg';
import LabMetrology from '../assets/researching/Labmetrology.jpg'
import Evia from '../assets/researching/eVia.jpg'
import FuelCell from '../assets/researching/FuelCell1.jpg'

function HomeCarousel() {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel
    
      activeIndex={index}
      onSelect={handleSelect}
      data-bs-theme="dark"
    >
      <Carousel.Item className=''>
        
        <img
          className="d-block w-80"
          src={FuelCell}
          alt='First slide of Fuel cell testing, the early days. Includes flasks, beakers, metal boxes and a computer screen with test images'
        fluid='true'
        />
        
        <Carousel.Caption>
          <h3 className="text-3xl bg-light font-bold tracking-tight sm:text-4xl">Fuel Cell Testing- The Early Days</h3>
          
          </Carousel.Caption>
      
      </Carousel.Item>
      <Carousel.Item className=''>
        <img
className="d-block"
          src={Evia}
          alt='Basic Fuel Cell Diagram. A drawing with the processes mapped out'
        />
        <Carousel.Caption>
          <h3 className="text-3xl bg-light font-bold tracking-tight sm:text-4xl ">Fuel Cell Diagram -The Basic Concept</h3>
         
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className=' '>
        <img
          className='d-block w-50'
          src={LabMetrology}
          alt='Two lab technicians, a woman with long brown hair and a man with thinning hair, looking at a paper with data on it infront of testing equipment'
          fluid='true'
        />
        <Carousel.Caption>
          <h3 className="text-3xl bg-light font-bold tracking-tight sm:text-4xl">Metrology</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="w-80 h-80">
        <img src={LabFlowTest} className='d-block h-50 w-50'
          alt="Lab Flow Testing. Male labe technician standing at a sink with a clip board infront of several pieces of equiptment"
        fluid='true'/> 
        <Carousel.Caption>
          <h3 className="text-3xl bg-light font-bold tracking-tight sm:text-4xl">Lab Flow Testing</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

export default HomeCarousel
