import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Bill from './BillWade.PNG'
const people = [
    {
      name: 'Bill Wade',
      role: 'Corporate Management and Innovation at Wade & Partners ',
      imageUrl: Bill,
        
      linkedinUrl: 'https://www.wade-partners.com/',
    },
  
      // 
    // More people...
  ]
  
  export default function Advisors() {
    return (
      <Container fluid="md">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Advisors</h2>
        <Row className="justify-content-md-center">
          <Card style={{ width: '18rem' }}>
        
            {people.map((person) => (
              <Card.Body key={person.name}>
                <Card.Img className="aspect-[3/2] w-full rounded-2xl object-cover" src={person.imageUrl} alt="" />
                <Card.Title className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</Card.Title>
                
             
            
                  <Card.Text className="text-base leading-7 text-gray-600">{person.role}
                            
                 
                  <a target="blank" href={person.linkedinUrl} className="text-gray-400 hover:underline">
                      <span className="sr-only">Link to Corporate Site: Wade & Partners</span>
                      
                  </a>
                  </Card.Text>
                
              </Card.Body>
            ))}
         
        </Card>
        </Row>
      </Container>
    )
  }