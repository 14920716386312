// import { Link } from 'react-router-dom'
import React from 'react'
import HomeCarousel from '../../components/HomeCarousel'
import { Container } from 'react-bootstrap'


  // More people...

const About = () => {
  return (
    <>
      <Container fluid='md'>
        <div className='space-y-5 sm:space-y-4'>
          <h1 className='text-3xl font-bold tracking-tight sm:text-4xl'>
            Established in 2001
          </h1>
          <br />

          <div className='text-xl text-gray-500'>
            <h5>
              Swift Enterprises is working to advance the areas of power,
              energetics, and propulsion at the practical level through
              discovery. Our facilities allow Swift to perform laboratory
              exploration, process and performance development at the
              labroratory level.We utilize the services of industry-recognized third-party
              laboratories to validate in-house findings. Along with its
              advisors and partners, Swift Enterprises is continuing to enable
              the United States and the world in energy independence.
            </h5>
           <HomeCarousel />
          </div>
              </div>
              <br />


          </Container>
          <br />
    </>
  )
}

export default About
