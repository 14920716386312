import React from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { Row, Container, Col } from 'react-bootstrap'
import people from '../people';


const Meet = () => {
  return (
      <>
          <Container>
      <Row className='justify-content-md-center'>
          {people.map((people) => (
            <React.Fragment key={people._id}>
              <Col>
                <Row>
                  <Card className="my-3 p-3"style={{ width: '20rem' }}>
                    <Col>
                      <Card.Img
                        variant='top'
                        src={people.imageUrl}
                        alt={people.alt}
                      />
                    </Col>
                    <Col>
                      <Card.Body>
                        <Card.Title>{people.name}</Card.Title>
                        <Card.Title className='text-primary'>
                          {people.role}
                        </Card.Title> <h5>Experience</h5>
                                      <Card.Text>
                                     
                          <li>{people.experience1}</li>
                          <li>{people.experience2}</li>
                          <li>{people.experience3}</li>
                        </Card.Text>
                      </Card.Body>
                                  <ListGroup className='list-group-flush'>
                                      <h5>Education</h5>
                        <ListGroup.Item>{people.education1}</ListGroup.Item>
                        <ListGroup.Item>{people.education2}</ListGroup.Item>
                        <ListGroup.Item>{people.education3}</ListGroup.Item>
                        {/* <ListGroup.Item><Link to={`/people/${people.resume}`}>View Resume</Link></ListGroup.Item> */}
                                  </ListGroup>
                    </Col>
                  </Card>
                </Row>
                  </Col>
                  <br />
            </React.Fragment>
          ))}
              </Row>
          </Container>
      </>
  )
}
export default Meet