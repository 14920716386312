
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from "react-router-bootstrap";

import Logo from '../assets/images/SwiftLogoSimpleWhite.png'
function Header() {
  // const navigate = useNavigate();
  return (
<>
      <Navbar
       sticky="top"
        expand='md'
        className='navbar navbar-dark bg-dark text-light'
      >
        <Container>
          <Navbar className='link-hover'>
            <Container>
              
                <Navbar.Brand><LinkContainer to='/'>
                  <img
                    src={Logo}
                    width='180em'
                    height='100em'
                    className='d-inline-block align-top'
                    alt='Swift Enterprises Logo'
                  /></LinkContainer>
                  <br />
                  <Container className='bg-light mt-3'>
                    <h5 className='text-primary'>Clean Efficient Renewable</h5>
                  </Container>
                </Navbar.Brand>
              
            </Container>
          </Navbar>

          <Navbar.Toggle
            className='border border-white'
            aria-controls='basic-navbar-nav '
          />
          <Navbar.Collapse
            className='text-light'
            id='basic-navbar-nav '
          >
            <Nav className='m-auto text-light'>
              <LinkContainer to='/'>
              <Nav.Link   className='text-light'>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/meet'>
                <Nav.Link  className='text-light'>Meet the Team</Nav.Link>
            
              </LinkContainer>
              <LinkContainer to='/dircell'>
                <Nav.Link  className='text-light'>Direct Fuel Cell</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/renewfuel'>
                <Nav.Link  className='text-light'>Renewable Fuels</Nav.Link>
             </LinkContainer>
         <LinkContainer to='/advisors'>
              <Nav.Link  className='text-light'>Advisors</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
