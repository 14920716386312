import { Container } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import './App.css'

const App = () => {
  return (
    <>
      
      <Header
        sticky='top'
        className='sticky-nav header'
      />

      <main className='py-1'>
        <Container fluid>
          <Outlet />
        </Container>
        <Footer
          fixed='bottom'
          className='footer'
        />
      </main>
    </>
  )
}
export default App
