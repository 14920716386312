// import CardFooter from 'react-bootstrap/CardFooter';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (<Container fluid>
    <footer className='bg-primary text-light'>
      
        <Row>
          <Col className='text-center py-3'>
            <Link to="mailto:john.rusek@swiftenterprises.com; mary.rusek@swiftenterprises.com" className="text-light">Contact</Link>
                  </Col>
                  <Col className='text-center py-3'>
            <p>Swift Enterprises &copy; {currentYear}</p>
                  </Col>
          <Col className='text-center py-3'>
            <Link className='text-light' to="/privacypolicy">
              <p>Privacy Policy</p>
            </Link>
          </Col>
        </Row>
     
    </footer> </Container>
  );
};
export default Footer;