// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-nav {
  position: sticky;
  top: 0;
}

.footer {
  
  bottom: 0;
  position:absolute;
left:0;

right:0;

}
a:hover {
  text-decoration: underline;
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;AACR;;AAEA;;EAEE,SAAS;EACT,iBAAiB;AACnB,MAAM;;AAEN,OAAO;;AAEP;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".sticky-nav {\n  position: sticky;\n  top: 0;\n}\n\n.footer {\n  \n  bottom: 0;\n  position:absolute;\nleft:0;\n\nright:0;\n\n}\na:hover {\n  text-decoration: underline;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
