import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import About from './screens/About/About';
import Dircell from './screens/Dircell/Dircell';
import Advisors from './screens/Advisors/Advisors';
import RenewFuel from './screens/Renewfuel/Renewfuel';
import PrivacyPolicy from './screens/Privacy';
import Renewfuelvideo from './screens/Renewfuel/RenewfuelVideo';
import Meet from './screens/Meet';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path='/'
      element={<App />}
    >
      <Route
       
        path='/'
        element={<About />}
      />
      <Route
        path='/meet'
        element={<Meet />}
      />
      <Route
        path='/dircell'
        element={<Dircell />}
      />
      <Route
        path='/advisors'
        element={<Advisors />}
      />
      <Route
        path='/renewfuel'
        element={<RenewFuel />}
      />
      <Route
        path='/privacypolicy'
        element={<PrivacyPolicy />}
      />
      <Route
        path='/renewfuelvideo'
        element={<Renewfuelvideo />}
      />
     
     
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

reportWebVitals()